import React, { Component } from "react";
import { GoogleApiWrapper } from "google-maps-react";
import Location from "./CurrentLocation";

export class Map extends Component {
  render() {
    return <Location centerAroundCurrentLocation google={this.props.google} />;
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyDfmQ0AIMi_zKJMpNoD5XTBDcQ1C2G-Osk",
})(Map);
