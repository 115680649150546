import React, { Component } from "react";

class ImageWithLoadStatus extends Component {
  constructor(props) {
    super(props);
    this.state = { imageStatus: "loading" };
  }

  handleImageLoaded() {
    this.setState({ imageStatus: "loaded" });
    this.props.imageLoaded();
  }

  componentDidUpdate(prevProps) {
    if (this.props.imageUrl !== prevProps.imageUrl) {
      this.setState({ imageStatus: "loading" });
    }
  }

  render() {
    return (
      <div style={{ position: "relative" }}>
        <div
          style={{
            position: "absolute",
            width: "100%",
            top: "0",
            bottom: "0",
            left: "0",
            background: "white",
            transition: "opacity 800ms ease-in-out",
            opacity: this.state.imageStatus === "loaded" ? "0" : "1"
          }}
        />
        <img
          className="location-card__image"
          style={{ width: "100%" }}
          src={this.props.imageUrl}
          onLoad={() => this.handleImageLoaded()}
          alt=""
        />
      </div>
    );
  }
}

export default ImageWithLoadStatus;
