import React, { useState, useEffect } from "react";

const Image = ({ image, alt }) => {
  const [loaded, setLoading] = useState(false);

  return (
    <>
      <img
        src={image}
        alt={alt && alt}
        onLoad={() => setLoading(true)}
        className={!loaded ? "img--loading" : "img--loaded"}
      />
    </>
  );
};

export default Image;
