import React from "react";

const JobInfoPopup = ({ selectedJob, handleJobInfoClick }) => {
  let jobDescription = selectedJob.job.description.substr(0, 120);
  jobDescription = jobDescription.trim();
  jobDescription = `${jobDescription}<a href="${
    selectedJob.job.link
  }" class="job-info__read-more" target="_blank" rel="noopener noreferrer">READ IN FULL</a>`;

  const { title, salary, sector, link } = selectedJob.job;
  const { location } = selectedJob;

  return (
    <article className="job-info">
      <div className="job-info__header">
        <svg
          className="job-info__close"
          onClick={handleJobInfoClick}
          width="37"
          height="37"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g transform="translate(1 1)" stroke="#008AC0" fill="none">
            <circle strokeWidth="2" cx="17.5" cy="17.5" r="17.5" />
            <path
              d="M12.426 12.606l10.52 10.52M22.63 12.606l-10.519 10.52"
              strokeWidth="3"
            />
          </g>
        </svg>
        {title && title.trim() !== "" && (
          <h2
            className="job-info__title"
            dangerouslySetInnerHTML={{ __html: title }}
          />
        )}
      </div>

      <span className="job-info__detail job-info__detail--location">
        <svg width="15" height="15" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M14.926.991L8.109 14.636c-.121.248-.323.373-.607.373a.897.897 0 0 1-.16-.022.672.672 0 0 1-.522-.66v-6.14H.684a.67.67 0 0 1-.66-.523.69.69 0 0 1 .042-.448.644.644 0 0 1 .31-.32L14.01.076A.64.64 0 0 1 14.319 0c.192 0 .351.068.48.203.106.099.171.222.196.367a.648.648 0 0 1-.069.421z"
            fill="#212F69"
            fillRule="nonzero"
          />
        </svg>
        {location}
      </span>

      {salary && salary.trim() !== "" && (
        <span className="job-info__detail job-info__detail--salary">
          Salary: {salary}
        </span>
      )}

      {sector && sector.trim() !== "" && (
        <span className="job-info__detail job-info__detail--sector">
          Sector: {sector}
        </span>
      )}

      {selectedJob.job.description !== "" && (
        <div className="job-info__description">
          <div dangerouslySetInnerHTML={{ __html: jobDescription }} />
        </div>
      )}

      {link && link.trim() !== "" && (
        <a
          href={link}
          className="btn btn-primary job-info__apply-btn"
          target="blank"
        >
          Apply Now
        </a>
      )}
    </article>
  );
};

export default JobInfoPopup;
