import React, { Component } from "react";
import Jobs from "./Jobs";
import Loading from "../shared/loading/Loading";
import "../../vendor/css/glide.core.min.css";
import Glide from "@glidejs/glide";
import ImageWithLoadStatus from "./ImageWithLoadStatus";

class Popup extends Component {
  constructor(props) {
    super(props);
    this.state = { fadeInClass: "", timerId: "" };
  }

  // 
  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.data.showPopup &&
      this.props.data.selectedPlace.name !== prevProps.data.selectedPlace.name
    ) {
      this.setState({
        fadeInClass: this.props.data.showPopup ? " location-card--fade-in" : ""
      });
    }

    if (
      this.props.data.selectedPlaceJobs.length > 0 &&
      document.querySelector(".glide")
    ) {
      // Initialising the glide module
      new Glide(".glide", {
        type: "slider",
        peek: {
          before: 0,
          after: 60
        },
        gap: 5
      }).mount();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.state.timerId);
  }

  closeHandler = () => this.props.onClose();

  handleQuickViewClick = details => {
    this.props.handleJobInfo(details);
  };

  handleLoad = () => {
    let timerId = setTimeout(() => {
      this.setState({
        fadeInClass: ""
      });
    }, 600);

    this.setState({
      timerId: timerId
    });
  };

  render() {
    const {
      showPopup,
      jobsLoaded,
      selectedPlaceJobs,
      errorMessage
    } = this.props.data;
    const { name, description, jobCount, url } = this.props.data.selectedPlace;
    let jobsStatusClass = "";
    if (jobsLoaded === false) jobsStatusClass = " location-card__jobs--loading";
    if (selectedPlaceJobs === false)
      jobsStatusClass = " location-card__jobs--error";
    if (selectedPlaceJobs.length > 0)
      jobsStatusClass = " location-card__jobs--loaded";

    return (
      <article
        className={"card location-card" + this.state.fadeInClass}
        style={{
          visibility: showPopup ? "visible" : "hidden",
          opacity: showPopup ? "1" : "0",
          zIndex: showPopup ? "9" : "-1"
        }}
      >
        {this.props.data.selectedPlace.image && (
          <ImageWithLoadStatus
            imageUrl={this.props.data.selectedPlace.image}
            imageLoaded={this.handleLoad}
          />
        )}

        <div className="location-card__title-container">
          <svg
            className="location-card__title-icon"
            width="20"
            height="20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19.886 1.32L10.802 19.49c-.16.331-.43.497-.81.497-.046 0-.117-.01-.212-.029a.857.857 0 0 1-.504-.319.894.894 0 0 1-.192-.56V10.9H.908a.894.894 0 0 1-.56-.191.857.857 0 0 1-.32-.504.92.92 0 0 1 .057-.596.858.858 0 0 1 .412-.426L18.666.099c.123-.066.26-.099.411-.099.256 0 .469.09.64.27.141.132.229.295.262.49a.862.862 0 0 1-.093.56z"
              fill="#212F69"
            />
          </svg>
          <h2 className="location-card__title">{name}</h2>
        </div>
        
        {/* Start of the scrollable container */}
        <div className="location-card__container-scroll">
          <svg
            className="location-card__close"
            onClick={this.closeHandler}
            width="27"
            height="27"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g
              transform="translate(1 1)"
              stroke="#008AC0"
              fill="none"
              fillRule="evenodd"
            >
              <circle strokeWidth="2" cx="12.5" cy="12.5" r="12.5" />
              <path
                d="M8.876 9.004l7.514 7.514M16.165 9.004L8.65 16.518"
                strokeWidth="3"
                strokeLinecap="round"
              />
            </g>
          </svg>
          <div className="location-card__desc-container">
            <p className="location-card__description">{description}</p>
            <div className="location-card__active-jobs-container">
              <h4 className="location-card__active-jobs">ACTIVE JOBS</h4>
              <p className="location-card__active-jobs-count">{jobCount}</p>
            </div>
          </div>
          <a
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-primary location-card__link"
          >
            <svg
              className="location-card__link-icon"
              width="18"
              height="18"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g
                fillRule="nonzero"
                stroke="#008AC4"
                strokeWidth="2"
                fill="none"
              >
                <rect x="1" y="1" width="6" height="6" rx="1" />
                <rect x="1" y="11" width="6" height="6" rx="1" />
                <rect x="11" y="1" width="6" height="6" rx="1" />
                <rect x="11" y="11" width="6" height="6" rx="1" />
              </g>
            </svg>
            View Area
          </a>
          <div className={"location-card__jobs" + jobsStatusClass}>
            <h3 className="location-card__jobs-header">Hot jobs near by</h3>

            <div className="location-card__jobs-message-container">
              {jobsLoaded === false && (
                <div
                  style={{
                    position: "relative",
                    backgroundColor: "white",
                    padding: "0 0 15px",
                    height: "126px"
                  }}
                  className="location-card__jobs-loading"
                >
                  <Loading />
                  <h2
                    style={{
                      fontSize: "16px",
                      position: "absolute",
                      top: "95px",
                      width: "100%"
                    }}
                  >
                    Loading the latest jobs
                  </h2>
                </div>
              )}

              {selectedPlaceJobs === false && (
                <p className="location-card__jobs-error">{errorMessage}</p>
              )}
            </div>
            {selectedPlaceJobs.length > 0 && (
              <div className="glide">
                <div data-glide-el="track" className="glide__track">
                  <ul className="glide__slides">
                    {selectedPlaceJobs.map((job, i) => (
                      <li className="glide__slide" key={`${job.title}-${i}`}>
                        <Jobs
                          job={job}
                          title={job.title}
                          added={job.added}
                          link={job.link}
                          location={selectedPlaceJobs.location}
                          handleJobInfo={this.handleQuickViewClick}
                        />
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
          </div>
        </div>
      </article>
    );
  }
}

export default Popup;

