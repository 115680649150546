import React, { useState, useEffect } from "react";
import headerImg from "../../assets/images/trendingHeader.png";
import closeButton from "../../assets/images/closeButton.svg";
import Loading from "../shared/loading/Loading";
import Stage0 from "./Stage0";
import Stage1 from "./Stage1";
import SingleJob from "./SingleJob";
import Image from "../Image";
import JobsService from "../../services/content/api/JobsService";

const Sidebar = ({
  mapStage,
  job,
  jobs,
  trendingLocations,
  openPanel,
  isLoadingJobs,
  handleIsLoadingJobs,
  handleMapStage,
  handleClick,
  searchInput,
}) => {
  // * Sets the base amount of data to display on the infinite scroll component
  const infiniteScrollBase = 15;

  // * Holds the state of whether the menu is open or not
  const [menuOpen, setmenuOpen] = useState(openPanel);

  // * Define loading state between job API calls
  const [loadingJobs, setLoadingJobs] = useState(isLoadingJobs);

  // * The total job counter on the sidebar
  const [totalJobs, setTotalJobs] = useState(0);

  // * Holds the information on a single job selected
  const [singleJob, setSingleJob] = useState({});

  // * Defines the amount of data the infinite scroll component will display
  const [jobAmounts, setJobAmounts] = useState(infiniteScrollBase);

  // * Holds the trending jobs data
  const [trendingData, setTrendingData] = useState(
    trendingLocations.slice(0, jobAmounts)
  );

  // * Holds the LEA jobs data when an area is selected
  const [areaJobsData, setAreaJobsData] = useState(jobs.slice(0, jobAmounts));

  useEffect(() => {
    // * If the 'jobAmounts' state changes which is the infinite scroll, increase the amount of jobs to display with a 0.25s delay
    if (mapStage === 0) {
      setTimeout(() => {
        setTrendingData(trendingData.slice(0, jobAmounts));
      }, 250);
    } else if (mapStage === 1) {
      setTimeout(() => {
        setAreaJobsData(jobs.slice(0, jobAmounts));
      }, 250);
    }
  }, [jobAmounts]);

  useEffect(() => {
    setTrendingData(trendingLocations);
    let totalJobs = 0;
    trendingLocations.forEach((item) => {
      totalJobs += item.jobCount;
    });
    setTotalJobs(totalJobs);
  }, [trendingLocations]);

  useEffect(() => {
    if (job.jobCount == 0) {
      // setLoadingJobs(false);
      handleIsLoadingJobs();
    }

    if (jobs.length > 0 && job) {
      setAreaJobsData(jobs.slice(0, jobAmounts));
      // setLoadingJobs(false);
      handleIsLoadingJobs();
    } else if (!jobs) {
      // setLoadingJobs(false);
      handleIsLoadingJobs();
    }
  }, [jobs]);

  useEffect(() => {
    // * when the user switches mapStage, revert the jobAmounts to the base amount
    setJobAmounts(infiniteScrollBase);
    if (mapStage === 1) {
      setSingleJob({});
    }
  }, [mapStage]);

  useEffect(() => {
    setmenuOpen(openPanel);
  }, [openPanel]);

  useEffect(() => {
    setLoadingJobs(isLoadingJobs);
  }, [isLoadingJobs]);

  const handleMenuOpen = () => {
    setmenuOpen(true);
  };

  const fetchMoreData = () => {
    setJobAmounts(jobAmounts + 5);
  };

  const handleSingleJobClick = (job) => {
    let jobSlug = job.link;

    // * Get the slug from the link to use as a parameter for getSingleVacancy API call
    jobSlug = jobSlug.substring(jobSlug.indexOf("/vacancy/") + 9);
    if (jobSlug.endsWith("/")) {
      jobSlug = jobSlug.slice(0, -1);
    }

    new JobsService().getSingleVacancy(jobSlug).then(async (job) => {
      job = job.data[0];
      new JobsService().getContractTypes().then(async (contractTypes) => {
        let contractType = "";
        contractTypes.data.forEach((item) => {
          // * Find a match between ID's then return the contract name as a string
          if (item.id === job?.vacancy_contract[0]) {
            contractType = item.name;
            job.contractType = contractType;
            setSingleJob(job);
            return;
          } else {
            setSingleJob(job);
          }
        });
      });
    });
    handleMapStage(2);
  };

  function GetMenuText() {
    if (mapStage === 0) {
      if (menuOpen) {
        return "Search All Jobs";
      }
      return "Trending Locations";
    } else if (mapStage === 1) {
      if (menuOpen) {
        return "Search All Jobs";
      }
      return "Show Job Results";
    } else if (mapStage === 2) {
      if (menuOpen) {
        return "Apply Now";
      }
      return "Open Job Listing";
    }
  }

  const handleAreaJobs = (job) => {
    // * Advance the user onto the next stage of the map, use the numbers to decide the zoom level
    handleClick(job, job.locationType === "lea" ? 9 : 11);
  };

  return (
    <>
      <div
        className={`map__sidebar ${mapStage === 2 && "map__sidebar--extended"}`}
      >
        <div id="sidebar">
          <div
            className={`sidebar__search ${
              menuOpen && "sidebar__search--active"
            }`}
          >
            <div className="sidebar__search__wrapper">
              <div className="sidebar__container">
                <div
                  className="sidebar__hero__trending__closeBtn"
                  onClick={() => setmenuOpen(false)}
                >
                  <img src={closeButton} alt="Close" />
                </div>

                <div
                  className={`${
                    mapStage !== 2 ? "sidebar__inner" : "sidebar__inner--alt"
                  }`}
                >
                  <div
                    className={`sidebar__hero ${
                      menuOpen && "sidebar__hero--open"
                    } ${mapStage === 2 && "sidebar__hero--alt"}`}
                  >
                    {mapStage !== 2 && (
                      <div className="sidebar__hero__image">
                        {mapStage == 0 && (
                          <img src={headerImg} alt="Trending" />
                        )}
                        {mapStage == 1 && job && job.image && (
                          <Image
                            startImage={headerImg}
                            image={job.image ? job.image : headerImg}
                            alt="Job Location Image"
                          />
                        )}
                      </div>
                    )}

                    {mapStage !== 2 && (
                      <div className="sidebar__hero__trending__container">
                        <div className="sidebar__hero__trending">
                          <div
                            className={`sidebar__hero__trending__header ${
                              mapStage === 1 &&
                              "sidebar__hero__trending__header--alt"
                            }`}
                          >
                            <h1>
                              {mapStage === 0 ? "Trending Locations" : job.name}
                            </h1>
                          </div>
                          <div className="sidebar__hero__trending__wrapper">
                            <div className="sidebar__hero__trending__subHeader">
                              <h2>Active jobs</h2>
                            </div>
                            <div className="sidebar__hero__trending__number">
                              {mapStage === 0 && (
                                <span className="jobCount">{totalJobs}</span>
                              )}
                              {mapStage === 1 && job.jobCount > 0 && (
                                <span className="jobCount">
                                  {/* {areaJobsData.length} */}
                                  {searchInput !== ""
                                    ? areaJobsData.length
                                    : job.jobCount}
                                </span>
                              )}
                              {mapStage === 1 && job.jobCount == 0 && (
                                <span className="jobCount">0</span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {mapStage === 0 && (
                      <Stage0
                        jobAmounts={jobAmounts}
                        fetchMoreData={fetchMoreData}
                        loadingJobs={loadingJobs}
                        trendingLocations={trendingLocations}
                        handleAreaJobs={handleAreaJobs}
                      />
                    )}

                    {mapStage === 1 &&
                      jobs.length > 0 &&
                      Array.isArray(areaJobsData) && (
                        <Stage1
                          areaJobsData={areaJobsData}
                          jobAmounts={jobAmounts}
                          fetchMoreData={fetchMoreData}
                          loadingJobs={loadingJobs}
                          handleSingleJobClick={handleSingleJobClick}
                        />
                      )}
                    {mapStage === 2 && (
                      <SingleJob
                        singleJob={singleJob}
                        job={job}
                        menuOpen={menuOpen}
                        mapStage={mapStage}
                      />
                    )}

                    {loadingJobs && (
                      <div className="loadingContainer--alt">
                        <Loading disabledBackground={true} />
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* !!! This code needs a severe refactor, implemented due to time restraints, come back later */}

              {mapStage !== 2 && (
                <div
                  className={`sidebar__search__inner ${
                    menuOpen && "sidebar__search__inner--active"
                  }`}
                >
                  {(mapStage === 1 && menuOpen) ||
                  (mapStage === 0 && menuOpen) ? (
                    <a href="https://engage-education.com/teaching-vacancies/">
                      <button
                        className={`btn btn--trendingLocations ${
                          menuOpen && `btn--trendingLocationsActive`
                        }`}
                        onClick={() => handleMenuOpen()}
                      >
                        <GetMenuText />
                      </button>
                    </a>
                  ) : (
                    <button
                      className={`btn btn--trendingLocations ${
                        menuOpen && `btn--trendingLocationsActive`
                      }`}
                      onClick={() => handleMenuOpen()}
                    >
                      <GetMenuText />
                    </button>
                  )}
                </div>
              )}

              {mapStage === 2 && (
                <div
                  className={`sidebar__search__inner  ${
                    menuOpen &&
                    "sidebar__search__inner--active sidebar__search__inner--applyNow"
                  }`}
                >
                  {menuOpen ? (
                    <a href={singleJob.link}>
                      <button
                        className={`btn btn--trendingLocations ${
                          menuOpen && `btn--applyNow`
                        }`}
                        onClick={() => handleMenuOpen()}
                      >
                        <GetMenuText />
                      </button>
                    </a>
                  ) : (
                    <button
                      className={`btn btn--trendingLocations ${
                        menuOpen && `btn--applyNow`
                      }`}
                      onClick={() => handleMenuOpen()}
                    >
                      <GetMenuText />
                    </button>
                  )}
                </div>
              )}
              {/* <div className="sidebar__locations"></div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
