const parseParagraphTags = (text) => {
  let parsedText = text.replace(/<p>\s*<\/p>/gi, "");
  parsedText = text.replace(/<p>\s*<img[^>]*>\s*<\/p>/gi, (match) => {
    return match.replace(/<p>/i, "").replace(/<\/p>/i, "");
  });

  parsedText = parsedText.replace(/<p>\s*&nbsp;\s*<\/p>/gi, (match) => {
    return match.replace(/<p>/i, "").replace(/<\/p>/i, "<br>");
  });

  // find all paragraph tags with just spaces in them and remove them
  parsedText = parsedText.replace(/<p>\s*<\/p>/gi, "");
  parsedText = parsedText.replace(/<p>\s*<img[^>]*>\s*<\/p>/gi, (match) => {
    return match.replace(/<p>/i, "").replace(/<\/p>/i, "");
  });

  // remove all inline styles from the text and replace them with spaces instead

  return parsedText;
};

export default parseParagraphTags;
