import content from "./routes.json";

export const BASE_URL = content.api.base_url;
export const CITY_NAMES_ENDPOINT = BASE_URL + content.api.city_taxonomy;
export const LEA_NAMES_ENDPOINT = BASE_URL + content.api.lea_taxonomy;

export const VACANCY_ID_ENDPOINT = `${BASE_URL}${content.api.vacancy_id_taxonomy}`;

export const VACANCY_SLUG_ENDPOINT = `${BASE_URL}${content.api.vacancy_slug}`;
export const VACANCY_CONTRACT_ENDPOINT = `${BASE_URL}${content.api.vacancy_contract}`;

export const VACANCY_ENDPOINT = `${BASE_URL}${content.api.vacancy_taxonomy}`;
export const SUBLEA_VACANCY_ID_ENDPOINT = `${BASE_URL}${content.api.sublea_vacancy_id_taxonomy}`;
export const SUBLEA_VACANCY_ENDPOINT = `${BASE_URL}${content.api.sublea_vacancy_taxonomy}`;
