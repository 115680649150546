import {
  BASE_URL,
  VACANCY_ID_ENDPOINT,
  VACANCY_ENDPOINT,
  SUBLEA_VACANCY_ID_ENDPOINT,
  SUBLEA_VACANCY_ENDPOINT,
  VACANCY_SLUG_ENDPOINT,
  VACANCY_CONTRACT_ENDPOINT,
} from "./api";
import axios from "axios";
import { distanceInWords } from "date-fns";

class JobsService {
  async getCityId(cityName, isSubLea = false) {
    // Ensuring city names are correctly formatted for HTML
    let name = cityName
      .replace(/ /g, "-")
      .replace("&amp;", "and")
      .replace(/'/g, "")
      .toLowerCase();
    const endPoint = isSubLea
      ? `${SUBLEA_VACANCY_ID_ENDPOINT}${name}`
      : `${VACANCY_ID_ENDPOINT}${name}`;
    let cityId = await axios.get(endPoint).then((response) => {
      // If below meets then show message to explain why no jobs
      if (response.data.length === 0) return "";
      return response.data[0].id;
    });
    return cityId;
  }

  async getSingleVacancy(slug) {
    // Ensuring city names are correctly formatted for HTML
    const endPoint = VACANCY_SLUG_ENDPOINT;
    let singleVacancy = await axios
      .get(`${endPoint}${slug}`)
      .then((response) => {
        if (response.data.length === 0) return "";
        return response;
      });
    return singleVacancy;
  }

  async getContractTypes() {
    // Ensuring city names are correctly formatted for HTML
    const endPoint = VACANCY_CONTRACT_ENDPOINT;
    let contracts = await axios.get(`${endPoint}`).then((response) => {
      // If below meets then show message to explain why no jobs
      if (response.data.length === 0) return "";
      return response;
    });
    return contracts;
  }

  async getContentNumPages(cityId, isSubLea) {
    // get total amount of pages at the moment
    const endPoint = isSubLea
      ? `${SUBLEA_VACANCY_ENDPOINT}${cityId}&filter[orderby]=date&order=desc&per_page=100`
      : `${VACANCY_ENDPOINT}${cityId}&filter[orderby]=date&order=desc&per_page=100`;

    return await axios
      .get(endPoint)
      .then((response) => {
        if (response.data.length === 0) return [];

        // Check the latest job that is loading, is the one that matches the current popup window.
        // This protect the jobs from flashing and changing on the popup if the user has clicked several different markers before the jobs have loaded
        return response.headers["x-wp-totalpages"];
      })
      .catch((err) => console.log(err));
  }

  //
  async getCityVacancies(cityId, isSubLea, pageNum) {
    // get total amount of pages at the moment

    const endPoint = isSubLea
      ? `${SUBLEA_VACANCY_ENDPOINT}${cityId}&filter[orderby]=date&order=desc&per_page=100${
          pageNum !== undefined ? `&page=${pageNum}` : ""
        }`
      : `${VACANCY_ENDPOINT}${cityId}&filter[orderby]=date&order=desc&per_page=100${
          pageNum !== undefined ? `&page=${pageNum}` : ""
        }`;

    return await axios.get(endPoint).then((response) => {
      if (response.data.length === 0) return [];
      let selectedJobs = [];
      for (let i = 0; i <= 100; i++) {
        if (response.data[i]) {
          let salary = "";
          let sector = "";
          let description = "";
          let startDate = "";
          let jobDescription = "";
          let contractType = "";
          // Check for description
          if (response.data[i].content.rendered !== "")
            description = response.data[i].content.rendered;

          if (response.data[i]?.vacancy_contract[0]) {
            contractType = response.data[i].vacancy_contract[0];
          }

          if (response.data[i].acf) {
            // Check for salary
            if (response.data[i].acf.vac_salary !== "")
              salary = response.data[i].acf.vac_salary;

            if (response?.data[i]?.acf?.vac_job_description)
              jobDescription = response.data[i].acf?.vac_job_description;

            if (response.data[i].acf.vac_start_date !== "")
              startDate = response.data[i].acf.vac_start_date;

            if (response.data[i].acf.vac_salary !== "")
              salary = response.data[i].acf.vac_salary;

            // Check for sector
            if (response.data[i].acf.vac_sub_sector !== "")
              sector = response.data[i].acf.vac_sub_sector;
          }

          selectedJobs.push({
            title: response.data[i].title.rendered,
            added: this.getJobAddedDate(response.data[i].date_gmt),
            link: response.data[i].link,
            salary: salary,
            sector: sector,
            description: description,
            contractType: contractType,
            startDate: startDate,
            jobDescription: jobDescription,
          });
        }
      }
      return selectedJobs;
    });

    // Check the latest job that is loading, is the one that matches the current popup window.
    // This protect the jobs from flashing and changing on the popup if the user has clicked several different markers before the jobs have loaded
  }

  // Grab date and format
  getTodaysDate() {
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    let yyyy = today.getFullYear();
    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;
    return yyyy + "-" + mm + "-" + dd;
  }

  // Returns the date the job was posted
  getJobAddedDate(posted) {
    let postedDate = new Date(posted);
    let today = new Date();
    var result = distanceInWords(postedDate, today); // formatDistance (date-fns)
    return result;
  }

  //
  handleResponseError(response) {
    throw new Error("HTTP error, status = " + response.status);
  }
  handleError(error) {
    console.log(error.message);
  }
}

export default JobsService;
