import React from "react";

// Job card template
const Jobs = props => {
  function decodeHtmlEntities(string) {
    return { __html: string };
  }

  // Handle quick view for smaller breakpoints
  function handleQuickViewClick(e, link) {
    e.preventDefault();
    if (window.innerWidth > 720) {
      window.open(link);
      return;
    }

    handleJobInfo(props);
  }

  // Job added props and added message
  const { job, handleJobInfo } = props;
  let jobAddedMsg = `Added ${job.added} ago`;

  return (
    <div className="location-card__jobs-container">
      <h4
        className="location-card__jobs-title"
        dangerouslySetInnerHTML={decodeHtmlEntities(job.title)}
      />
      <p className="location-card__jobs-added">
        <svg
          width="17"
          height="16"
          xmlns="http://www.w3.org/2000/svg"
          className="location-card__jobs-icon"
        >
          <path
            d="M9.674 4.493v4.515c0 .094-.03.171-.09.232a.314.314 0 0 1-.233.09H6.123a.314.314 0 0 1-.232-.09.314.314 0 0 1-.09-.232v-.645c0-.094.03-.172.09-.232.06-.06.138-.09.232-.09h2.26V4.492c0-.094.03-.172.09-.232.061-.06.139-.09.233-.09h.645c.095 0 .172.03.232.09.06.06.091.138.091.232zm4.197 3.547c0-.994-.246-1.911-.737-2.751a5.468 5.468 0 0 0-1.997-1.996 5.368 5.368 0 0 0-2.754-.735c-.995 0-1.913.245-2.754.735A5.468 5.468 0 0 0 3.632 5.29a5.355 5.355 0 0 0-.737 2.751c0 .995.246 1.912.737 2.752a5.468 5.468 0 0 0 1.997 1.995c.84.49 1.759.736 2.754.736.995 0 1.913-.246 2.754-.736a5.468 5.468 0 0 0 1.997-1.995c.491-.84.737-1.757.737-2.752zm2.26 0c0 1.404-.347 2.7-1.04 3.885a7.709 7.709 0 0 1-2.82 2.817 7.584 7.584 0 0 1-3.888 1.038 7.584 7.584 0 0 1-3.889-1.038 7.709 7.709 0 0 1-2.82-2.817A7.566 7.566 0 0 1 .636 8.04c0-1.404.347-2.699 1.04-3.885a7.709 7.709 0 0 1 2.82-2.817A7.584 7.584 0 0 1 8.382.3c1.406 0 2.702.346 3.889 1.038a7.709 7.709 0 0 1 2.82 2.817A7.566 7.566 0 0 1 16.13 8.04z"
            fill="#626262"
          />
        </svg>
        {jobAddedMsg}
      </p>
      <p className="location-card__jobs-quick-look">
        <a
          href={job.link}
          onClick={e => handleQuickViewClick(e, job.link)}
          rel="noopener noreferrer"
        >
          <svg
            width="20"
            height="14"
            xmlns="http://www.w3.org/2000/svg"
            className="location-card__jobs-icon"
          >
            <path
              d="M18.378 6.86c-1.12-1.736-2.522-3.034-4.208-3.895.45.765.674 1.593.674 2.483 0 1.36-.484 2.525-1.452 3.492-.969.967-2.134 1.451-3.496 1.451S7.369 9.907 6.4 8.94c-.968-.967-1.452-2.131-1.452-3.492 0-.89.225-1.718.674-2.483-1.686.86-3.09 2.16-4.208 3.895.979 1.508 2.207 2.709 3.683 3.603a9.1 9.1 0 0 0 4.799 1.34 9.1 9.1 0 0 0 4.799-1.34c1.476-.894 2.704-2.095 3.683-3.603zm-7.952-4.237a.51.51 0 0 0-.155-.375.511.511 0 0 0-.375-.154c-.92 0-1.71.329-2.37.987-.658.658-.988 1.447-.988 2.367a.51.51 0 0 0 .155.375.511.511 0 0 0 .376.154.511.511 0 0 0 .375-.154.51.51 0 0 0 .155-.375c0-.633.224-1.173.673-1.622.45-.449.99-.673 1.624-.673a.511.511 0 0 0 .375-.155.51.51 0 0 0 .155-.375zm9.366 4.237c0 .25-.074.504-.221.761a11.711 11.711 0 0 1-4.158 4.066c-1.742 1.02-3.58 1.529-5.517 1.529-1.937 0-3.775-.512-5.517-1.534-1.741-1.023-3.127-2.376-4.158-4.06A1.526 1.526 0 0 1 0 6.86c0-.25.074-.504.22-.761 1.032-1.685 2.418-3.038 4.16-4.06C6.12 1.015 7.96.504 9.895.504s3.775.51 5.517 1.533c1.741 1.023 3.127 2.376 4.158 4.06.147.258.22.512.22.762z"
              fill="#ED1D25"
            />
          </svg>
          QUICK LOOK
        </a>
      </p>
    </div>
  );
};

export default Jobs;
