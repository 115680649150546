import { LEA_NAMES_ENDPOINT } from "./api";
import axios from "axios";

class LeaService {
  constructor() {
    this.service =
      LEA_NAMES_ENDPOINT + "?per_page=100&filter[orderby]=name&order=asc";
    this.leaData = [];
  }

  async asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  }

  async getNumPages() {
    const { headers } = await axios(this.service);
    return headers["x-wp-totalpages"];
  }

  async fetchLeaData(numPages) {
    const leaNames = [];
    let leaData = [];

    for (let page = 1; page <= numPages; page += 1) {
      const lea = axios.get(`${this.service}&page=${page}`);
      leaNames.push(lea);
    }

    await axios
      .all(leaNames)
      .then((response) => {
        leaData = response.map((res) => res.data);
        leaData = leaData.flat();
        return leaData;
      })
      .then((data) => {
        leaData = [];
        data.forEach((item) => {
          if (item.name !== "-") {
            var itemData = {
              name: item.name,
              jobCount: item.count,
            };
            if (item.acf.trending_location) {
              itemData.trending_location = true;
            } else {
              itemData.trending_location = false;
            }
            if (item.acf.location_image && item.acf.location_image.url) {
              itemData.image = item.acf.location_image.url;
            }
            if (item.acf.seo_relationship) {
              const { post_name, post_excerpt, ID } =
                item.acf.seo_relationship[0];
              itemData.description = post_excerpt;
              itemData.url = `/vacancy-location/${post_name}`;
              itemData.id = ID;
            }
            if (item.acf.polygon) {
              const { polygon } = item.acf;

              const polygonData = JSON.parse(polygon);
              let polygonCoords = [];
              polygonData.forEach((polyData) => {
                let polygonCoordsHoles = [];
                let polygonDataToMap = [...polyData];

                if (polyData.length === 1) {
                  polygonDataToMap = [...polyData[0]];
                }
                polygonDataToMap.map((coord) =>
                  polygonCoordsHoles.push({
                    lat: parseFloat(coord[1]),
                    lng: parseFloat(coord[0]),
                  })
                );
                polygonCoords.push(polygonCoordsHoles);
              });

              itemData.polygon = polygonCoords;
              itemData.strokeColour = "#ED1D25";
              itemData.opacity = 0.8;
            }
            if (
              item.acf.lat &&
              item.acf.lat !== "" &&
              item.acf.long &&
              item.acf.long !== ""
            ) {
              const { lat, long } = item.acf;
              itemData.lat = parseFloat(lat.trim());
              itemData.long = parseFloat(long.trim());
            }
            if (item.acf.trending_location) {
              itemData.trending_location = true;
            } else {
              itemData.trending_location = false;
            }

            leaData.push(itemData);
          }
        });
        this.leaData = leaData;
      })
      .catch((e) => this.handleError(e));
    return true;
  }

  async retrieveLEAData() {
    return (this.leaData = this.getNumPages().then((numPages) =>
      this.fetchLeaData(numPages).then(() => {
        return this.leaData;
      })
    ));
  }
  handleResponseError(response) {
    throw new Error("HTTP error, status = " + response.status);
  }
  handleError(error) {
    console.log(error.message);
  }
}

export default LeaService;
