import { CITY_NAMES_ENDPOINT } from "./api";
import axios from "axios";

class CityService {
  constructor() {
    this.service =
      CITY_NAMES_ENDPOINT + "?per_page=100&filter[orderby]=name&order=asc";
    this.cityData = [];
  }

  async getNumPages() {
    const { headers } = await axios(this.service);
    return headers["x-wp-totalpages"];
  }

  async fetchCityData(numPages) {
    const cityNames = [];

    for (let page = 1; page <= numPages; page += 1) {
      const city = axios.get(`${this.service}&page=${page}`);
      cityNames.push(city);
    }

    await axios
      .all(cityNames)
      .then(response => {
        let cityData = response.map(res => res.data);
        cityData = cityData.flat();
        return cityData;
      })
      .then(data => {
        let cityData = [];
        data.forEach(item => {
          if (item.name !== "-") {
            var itemData = {
              name: item.name,
              jobCount: item.count
            };

            if (item.acf.location_image && item.acf.location_image.url) {
              itemData.image = item.acf.location_image.url;
            }

            if (item.acf.seo_relationship && item.acf.seo_relationship !== "") {
              const {
                post_name,
                post_excerpt,
                ID
              } = item.acf.seo_relationship[0];

              itemData.description = post_excerpt;
              itemData.url = `/vacancy-location/${post_name}`;
              itemData.id = ID;
            }
            if (item.acf.polygon) {
              const { polygon } = item.acf;
              const polygonData = JSON.parse(polygon);
              let polygonCoords = [];
              polygonData[0].map(coord =>
                polygonCoords.push({
                  lat: parseFloat(coord[1]),
                  lng: parseFloat(coord[0])
                })
              );
              itemData.polygon = polygonCoords;
              itemData.strokeColour = "#ED1D25";
              itemData.opacity = 0.8;
            }
            if (
              item.acf.lat &&
              item.acf.lat !== "" &&
              item.acf.long &&
              item.acf.long !== ""
            ) {
              const { lat, long } = item.acf;
              itemData.lat = parseFloat(lat.trim());
              itemData.long = parseFloat(long.trim());
            }
            cityData.push(itemData);
          }
        });
        this.cityData = cityData;
      })
      .catch(e => this.handleError(e));

    return true;
  }

  async retrieveCityData() {
    return (this.cityData = this.getNumPages().then(numPages =>
      this.fetchCityData(numPages).then(() => {
        return this.cityData;
      })
    ));
  }
  handleResponseError(response) {
    throw new Error("HTTP error, status = " + response.status);
  }
  handleError(error) {
    console.log(error.message);
  }
}

export default CityService;
