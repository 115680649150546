import React from "react";
import Map from "./components/map/Map";
import "./assets/sass/App.scss";
import Sidebar from "./components/sidebar/Sidebar";
function App() {
  return (
    <div className="app">
      <Map />
    </div>
  );
}

export default App;
