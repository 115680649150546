import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import parse from "html-react-parser";
import convertDate from "../../utils/convertDate";

const Stage1 = ({
  areaJobsData,
  loadingJobs,
  jobAmounts,
  fetchMoreData,
  handleSingleJobClick,
}) => {
  return (
    <div
      className={`sidebar__jobs__wrapper ${loadingJobs && "fullHeight"}`}
      id="scrollableDiv"
    >
      <div className="sidebar__jobs__overflow">
        <div className="sidebar__jobs">
          <InfiniteScroll
            dataLength={jobAmounts}
            next={fetchMoreData}
            hasMore={true}
            scrollableTarget="scrollableDiv"
          >
            {areaJobsData.map((job, index) => {
              return (
                <div className="sidebar__jobs__block" key={index}>
                  <div className="sidebar__job__singleJob">
                    <div className="sidebar__singleJob__title">
                      <h5>{parse(job.title)}</h5>
                    </div>
                    <div className="sidebar__singleJob__desc">
                      <div className="sidebar__singleJob__desc__block">
                        <div className="sidebar__singleJob__desc__block__label">
                          <span>Sector: </span>
                        </div>
                        <div className="sidebar__singleJob__desc__block__content">
                          <span>{job.sector ? job.sector : "n/a"}</span>
                        </div>
                      </div>
                      <div className="sidebar__singleJob__desc__block">
                        <div className="sidebar__singleJob__desc__block__label">
                          <span>Salary: </span>
                        </div>
                        <div className="sidebar__singleJob__desc__block__content">
                          <span>{job.salary ? job.salary : "n/a"}</span>
                        </div>
                      </div>
                      <div className="sidebar__singleJob__desc__block">
                        <div className="sidebar__singleJob__desc__block__label">
                          <span>Contract:</span>
                        </div>
                        <div className="sidebar__singleJob__desc__block__content">
                          <span>
                            {job.contractType ? job.contractType : "n/a"}
                          </span>
                        </div>
                      </div>
                      <div className="sidebar__singleJob__desc__block">
                        <div className="sidebar__singleJob__desc__block__label">
                          <span>Start Date: </span>
                        </div>
                        <div className="sidebar__singleJob__desc__block__content">
                          <span>
                            {job?.startDate
                              ? convertDate(job.startDate)
                              : "n/a"}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="sidebar__singleJob__cta">
                      <div className="sidebar__singleJob__cta__block__date">
                        <span>Added {job.added} ago</span>
                      </div>
                      <div className="sidebar__singleJob__cta__block__btn">
                        <button
                          className="btn btn--primary"
                          onClick={() => handleSingleJobClick(job)}
                        >
                          Quick View
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );
};

export default Stage1;
