import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";

const Stage0 = ({
  jobAmounts,
  fetchMoreData,
  loadingJobs,
  trendingLocations,
  handleAreaJobs,
}) => {
  return (
    <>
      <div
        className={`sidebar__jobs__wrapper ${loadingJobs && "fullHeight"}`}
        id="scrollableDiv"
      >
        <div className="sidebar__jobs__overflow">
          <div className="sidebar__jobs">
            <InfiniteScroll
              dataLength={jobAmounts}
              next={fetchMoreData}
              hasMore={true}
              scrollableTarget="scrollableDiv"
            >
              {trendingLocations.map((job, index) => {
                return (
                  <div className="sidebar__jobs__block" key={index}>
                    <div className="sidebar__job__image">
                      <img
                        src={
                          job.image
                            ? job.image
                            : "https://via.placeholder.com/480x250"
                        }
                        alt={job.name}
                      />
                    </div>
                    <div className="sidebar__job__content">
                      <div className="sidebar__job__header">
                        <h3>{job.name}</h3>
                      </div>
                      <div className="sidebar__job__subHeader">
                        <div className="sidebar__job__subHeader__text1">
                          <span className="jobCount--alt">{job.jobCount}</span>
                        </div>
                        <div className="sidebar__job__subHeader__text2">
                          <h4>Active jobs</h4>
                        </div>
                      </div>
                      <div className="sidebar__job__cta">
                        <button
                          className="btn btn--primary"
                          onClick={() => handleAreaJobs(job)}
                        >
                          Area jobs
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
            </InfiniteScroll>
          </div>
        </div>
      </div>
    </>
  );
};

export default Stage0;
