import React from "react";
import Loading from "../shared/loading/Loading";
import LeaService from "../../services/content/api/LeaService";
import CityService from "../../services/content/api/CityService";
import JobsService from "../../services/content/api/JobsService";
import Popup from "./Popup";
import JobInfoPopup from "./JobInfoPopup";
import CustomMarker from "./CustomMarker";
import Sidebar from "../sidebar/Sidebar";
import Searchbar from "../searchbar/Searchbar";
import toolTipImg from "../../assets/images/tooltip.svg";
import arrowBack from "../../assets/images/arrow--back.svg";
import { Map, Polygon, InfoWindow } from "google-maps-react";
import { mapPosition, mapStyle } from "./MapStyles";

export class Location extends React.Component {
  _isMounted = false;

  // Props Set
  constructor(props) {
    super(props);
    this.leaService = new LeaService();
    this.cityService = new CityService();
    this.jobsService = new JobsService();

    const { lat, lng } = this.props.initialCenter;

    this.state = {
      isLoading: true,
      mapStage: 0,
      currentLocation: {
        curLat: lat,
        curLng: lng,
      },
      initialCenter: {
        lat: lat,
        lng: lng,
      },
      trendingLocations: [],
      zoom: this.props.zoom,
      draggable: true,
      errorMessage: "",
      leas: [],
      filteredLeas: [],
      leasLoaded: false,
      cities: [],
      filteredCities: [],
      citeiesLoaded: false,
      allLocations: [],
      filteredAllLocations: [],
      showPopup: false,
      selectedPlace: {
        lat: lat,
        lng: lng,
      },
      jobsLoaded: false,
      selectedPlaceJobs: [],
      filteredSelectedPlaceJobs: [],
      activeJobDetail: {},
      showInfoWindow: false,
      info: {
        title: "",
        lat: 0,
        long: 0,
      },
      showPolygon: true,
      showMarker: true,
      strokeColor: "#ED1D25",
      loadingMessage: "Fetching coverage data",
      showJobInfoPopup: false,
      searchInput: "",
      menuOpen: false,
      isLoadingJobs: false,
      contractTypes: {},
    };
  }

  /* Runs when data has been fetched from the api within `services/content/api`,
  timeout will display a loading message. */
  componentDidMount() {
    this._isMounted = true;
    this.getCities();
    this.getLEAs();
    this.timeout = setTimeout(() => {
      this.setState({ loadingMessage: "Plotting data on the map" });
    }, 3000);
  }

  /* Unsubscribing from the previous Mount() */
  componentWillUnmount() {
    clearTimeout(this.timeout);
    this._isMounted = false;
  }

  checkIfAllLoaded() {
    if (this.state.leasLoaded && this.state.citiesLoaded) {
      this.setState({
        isLoading: false,
      });
    } else {
      return;
    }
  }

  /* Receiving data from the api within `services/content/api/CityService` */
  getCities() {
    this.cityService.retrieveCityData().then((cityData) => {
      if (this._isMounted) {
        // add an id field to each city object to be used in the map
        cityData.forEach((city) => {
          if (city.jobCount === 0) {
            cityData = cityData.filter((city) => city.jobCount > 0);
          }
          city.locationType = "city";

          if (city.trending_location) {
            this.setState({
              trendingLocations: [...this.state.trendingLocations, city],
            });
          }
        });

        this.setState({
          cities: cityData,
          filteredCities: cityData,
          allLocations: [...this.state.allLocations, ...cityData],
          filteredAllLocations: [...this.state.allLocations, ...cityData],
          citiesLoaded: true,
        });

        this.checkIfAllLoaded();
      }
    });
  }

  /* Receiving data from the api within `services/content/api/LeaService` */
  getLEAs() {
    this.leaService.retrieveLEAData().then((leaData) => {
      leaData.forEach((lea) => {
        if (lea.jobCount === 0) {
          leaData = leaData.filter((lea) => lea.jobCount > 0);
        }
        lea.locationType = "lea";

        if (lea.trending_location) {
          this.setState({
            trendingLocations: [...this.state.trendingLocations, lea],
          });
        }
      });

      this.setState({
        leas: leaData,
        filteredLeas: leaData,
        allLocations: [...this.state.allLocations, ...leaData],
        filteredAllLocations: [...this.state.allLocations, ...leaData],
        leasLoaded: true,
      });
      this.checkIfAllLoaded();
    });
  }

  handleBorderColor = (props, polygon, e) => {
    if (!polygon) return;

    polygon.setOptions({
      strokeColor: "#fff",
      fillOpacity: 0.6,
    });
  };

  removeBorderColor = (props, polygon, e) => {
    if (!polygon) {
      return;
    }

    polygon.setOptions({
      strokeColor: this.state.strokeColor,
      fillOpacity: 0.35,
    });
  };

  handleIsLoadingJobs = () => {
    this.setState({
      isLoadingJobs: false,
    });
  };

  handleNoJobsPopUp = (area) => {
    const div = document.createElement("div");
    div.className = "popup__noJobs";
    div.innerHTML = `
    <div class="popup__noJobs__inner">
      <span>There are no jobs in <span class="popup__noJobs__area">${area}</span> at this time.</span>
    </div>
    `;
    document.body.appendChild(div);
    setTimeout(() => {
      div.classList.add("activePopup");
    }, 100);

    setTimeout(() => {
      div.classList.remove("activePopup");
    }, 3000);

    setTimeout(() => {
      div.remove();
    }, 5000);
  };

  /* Handling Polygon click (map) */
  handleClick = (location, zoom) => {
    this.mapRef.props.google.maps.LatLngBounds = {
      north: 85,
      south: -85,
      west: -180,
      east: 180,
    };
    if (!location.url || location.jobCount === 0) {
      this.handleNoJobsPopUp(location.name);
      return;
    }
    if (this.state.mapStage === 1) {
      return;
    }
    this.mapRef.map.draggable = false;
    this.setState({
      searchInput: "",
      filteredLeas: [location],
      filteredCities: [],
      filteredAllLocations: [location],
      isLoadingJobs: true,
      zoom: zoom,

      draggable: false,
      currentLocation: {
        curLat: location.lat,
        curLng: location.long,
      },
      mapStage: 1,
      searchInput: "",
    });

    setTimeout(() => {
      this.setState({
        menuOpen: true,
      });
    }, 500);

    /* If there is a location then setting the popup to true and passing the selected location */
    this.setState({
      info: {
        title: "",
        lat: 0,
        long: 0,
      },
      showInfoWindow: false,
      jobsLoaded: false,
      selectedPlace: location,
      showPopup: true,
      showJobInfoPopup: false,
      selectedPlaceJobs: [],
      filteredSelectedPlaceJobs: [],
    });
    this.retrieveJobsData(location.name, location.locationType);
  };

  /* Using Job Service for error handling */
  retrieveJobsData(locationName, locationType) {
    this.jobsService.getCityId(locationName).then(async (id) => {
      let cityId = id;

      if (cityId === "") {
        await this.jobsService.getCityId(locationName, true).then((id) => {
          cityId = id;
        });
      }

      if (cityId === "") {
        this.setState({
          jobsLoaded: true,
          selectedPlaceJobs: false,
          filteredSelectedPlaceJobs: false,
          errorMessage: `Sorry, we were unable to retrieve jobs from ${this.state.selectedPlace.name}, please try again later.`,
        });
        return;
      }
      let numPages = 0;

      this.jobsService
        .getContentNumPages(cityId, locationType === "city")
        .then((numPages) => {
          this.jobsService.getContractTypes().then((contract) => {
            for (let i = 1; i <= numPages; i++) {
              this.jobsService
                .getCityVacancies(cityId, undefined, i)
                .then(async (jobs) => {
                  jobs.forEach((job) => {
                    function getContractType() {
                      let type = contract.data.find(
                        (contract) => contract.id === job.contractType
                      );
                      if (type?.name) {
                        return type.name;
                      } else {
                        return "n/a";
                      }
                    }
                    job.contractType = getContractType();
                  });

                  let selectedJobs = jobs;
                  if (!selectedJobs || selectedJobs.length === 0) {
                    await this.jobsService
                      .getCityVacancies(cityId, true, i)
                      .then((subLeaJobs) => {
                        selectedJobs = [...selectedJobs, ...subLeaJobs];
                      });
                  }

                  // If the current location name matches the current open popup, and selected jobs is empty
                  if (
                    !selectedJobs ||
                    (selectedJobs.length === 0 &&
                      this.state.selectedPlace.name === locationName)
                  ) {
                    this.setState({
                      jobsLoaded: true,
                      selectedPlaceJobs: false,
                      errorMessage: `Sorry, there are no jobs in ${this.state.selectedPlace.name} at the moment.`,
                    });
                    return; // Failed to load the jobs message
                  }

                  selectedJobs.location = locationName;

                  if (this.state.selectedPlace.name === selectedJobs.location) {
                    this.setState({
                      selectedPlaceJobs: [
                        ...this.state.selectedPlaceJobs,
                        ...selectedJobs,
                      ],
                      filteredSelectedPlaceJobs: [
                        ...this.state.selectedPlaceJobs,
                        ...selectedJobs,
                      ],
                      jobsLoaded: true,
                    });
                  }
                });
            }
          });
        });
    });
  }

  toggleJobInfoPopup = (details) => {
    this.setState({
      showJobInfoPopup:
        this.state.activeJobDetail.location === details.location
          ? true
          : !this.state.showJobInfoPopup,
      activeJobDetail: details,
    });
  };

  closePopup = () => {
    if (this.state.showPopup) {
      this.setState({
        showPopup: false,
        showJobInfoPopup: false,
      });
    }
  };

  toggleMapFeatures = (feature) => {
    if (feature === "polygon") {
      this.setState({
        showPolygon: !this.state.showPolygon,
      });
      return;
    }

    this.setState({
      showMarker: !this.state.showMarker,
    });
  };

  handleBack = (map) => {
    if (this.state.mapStage === 1) {
      this.mapRef.map.draggable = true;
      this.setState({
        zoom: 7,
        draggable: true,
        mapStage: 0,
        filteredLeas: this.state.leas,
        filteredCities: this.state.cities,
        filteredAllLocations: this.state.allLocations,
        isLoadingJobs: false,
        searchInput: "",
        currentLocation: {
          curLat: this.props.lat,
          curLng: this.props.lng,
        },
        menuOpen: false,
      });
    }
    if (this.state.mapStage === 2) {
      this.setState({
        mapStage: 1,
      });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.searchInput != prevState.searchInput) {
      if (this.state.mapStage === 0) {
        const filteredLocations = this.state.allLocations.filter((lea) => {
          return lea.name
            .toLowerCase()
            .includes(this.state.searchInput.toLowerCase());
        });

        this.setState({
          filteredAllLocations: filteredLocations,
        });
      } else {
        const filteredJobs = this.state.selectedPlaceJobs.filter((job) => {
          return job.title
            .toLowerCase()
            .includes(this.state.searchInput.toLowerCase());
        });

        this.setState({
          filteredSelectedPlaceJobs: filteredJobs,
        });
      }
    }
  }

  handleSearchInput = (e) => {
    this.setState({
      searchInput: e.target.value,
    });
  };

  handleMapStage = (stage) => {
    this.setState({
      mapStage: stage,
      searchInput: "",
    });
  };

  render() {
    const { isLoading } = this.state;
    const { curLat, curLng } = this.state.currentLocation;
    const { lat, lng } = this.state.initialCenter;
    const style = Object.assign({}, mapPosition.map);

    return (
      <div className="map-container" style={style}>
        <div className="map__container">
          {this.state.mapStage === 0 ? (
            <div className="map__tip">
              <div className="map__tip__icon">
                <img src={toolTipImg} alt="tooltip" />
              </div>
              <div className="map__tip__header">
                <span>Explore our live vacancies</span>
              </div>
              <div className="map__tip__subHeader">
                <span>
                  Click on an area on the map to see our full list of available
                  jobs!
                </span>
              </div>
            </div>
          ) : (
            <div
              className="map__backBtn"
              onClick={(map) => this.handleBack(this.map)}
            >
              <img src={arrowBack} alt="Back Button" />
            </div>
          )}
          <Sidebar
            searchInput={this.state.searchInput}
            mapStage={this.state.mapStage}
            handleMapStage={this.handleMapStage}
            job={this.state.selectedPlace}
            jobs={this.state.filteredSelectedPlaceJobs}
            contractTypes={this.state.contractTypes}
            trendingLocations={this.state.trendingLocations}
            openPanel={this.state.menuOpen}
            isLoadingJobs={this.state.isLoadingJobs}
            handleIsLoadingJobs={this.handleIsLoadingJobs}
            handleClick={this.handleClick}
          />
          {this.state.mapStage !== 2 && (
            <div className="map__searchbar">
              <Searchbar
                handleSearchInput={this.handleSearchInput}
                value={this.state.searchInput}
                placeholder={
                  this.state.mapStage === 0
                    ? "Search Job Locations"
                    : "Search Jobs In This Area"
                }
              />
            </div>
          )}
          <div className="map__component">
            <Map
              draggable={this.state.draggable}
              className="map"
              google={this.props.google}
              initialCenter={{ lat: lat, lng: lng }}
              center={{ lat: curLat, lng: curLng }}
              zoom={this.state.zoom}
              styles={mapStyle}
              ref="map"
              onClick={this.closePopup}
              ref={(ref) => {
                this.mapRef = ref;
              }}
            >
              {this.state.filteredCities.map((city, i) => {
                if (
                  city.lat &&
                  city.long &&
                  !city.polygon &&
                  this.state.showMarker
                ) {
                  return (
                    <CustomMarker
                      key={`city-${i}`}
                      position={{ lat: city.lat, lng: city.long }}
                      onClick={() => this.handleClick(city)}
                      name={city.name}
                      // label={city.jobCount > 0 ? "" + city.jobCount : ""}
                      icon={{
                        // Below url is temporary so it will work both on local and staging
                        url: `https://engage-education.com/wp-content/themes/engage/assets/marker-icon.svg`,
                        scaledSize: new window.google.maps.Size(25, 25),
                      }}
                    />
                  );
                }
              })}

              {this.state.filteredAllLocations.map((location, i) => {
                if (
                  location.polygon &&
                  location.polygon.length > 0 &&
                  this.state.showPolygon
                ) {
                  return (
                    <Polygon
                      key={i}
                      onMouseover={this.handleBorderColor}
                      onMouseout={this.removeBorderColor}
                      onClick={() =>
                        this.handleClick(
                          location,
                          location.locationType === "lea" ? 9 : 11
                        )
                      }
                      title={location.name}
                      paths={location.polygon}
                      strokeColor="#ED1D25"
                      strokeOpacity={0.8}
                      strokeWeight={2}
                      fillColor="#ED1D25"
                      fillOpacity={0.35}
                    />
                  );
                }
              })}

              <InfoWindow
                visible={this.state.showInfoWindow}
                position={{
                  lat: this.state.info.lat,
                  lng: this.state.info.long,
                }}
                onOpen={this.windowHasOpened}
                onClose={this.windowHasClosed}
              >
                <div>
                  <h2>{this.state.info.title}</h2>
                </div>
              </InfoWindow>
            </Map>
          </div>
        </div>

        {/* Key for show/hide leas and sub leas. Commented out for launch */}
        {/* <Key
          showMarker={this.state.showMarker}
          showPolygon={this.state.showPolygon}
          onChange={this.toggleMapFeatures}
        /> */}

        <div className="container">
          <Popup
            data={this.state}
            onClose={this.closePopup}
            handleJobInfo={this.toggleJobInfoPopup}
          />
        </div>
        {this.state.showJobInfoPopup && (
          <JobInfoPopup
            selectedPlace={this.state.selectedPlace}
            selectedJob={this.state.activeJobDetail}
            handleJobInfoClick={this.toggleJobInfoPopup}
          />
        )}

        {isLoading === true && (
          <div className="loading-container">
            <Loading />
            <p>{this.state.loadingMessage}</p>
          </div>
        )}
      </div>
    );
  }
}
export default Location;

Location.defaultProps = {
  zoom: 7,
  initialCenter: {
    lat: 52.637920390123654,
    lng: -1.128484543301026,
  },
  centerAroundCurrentLocation: false,
  visible: true,
};
