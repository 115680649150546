import React from "react";
import parse from "html-react-parser";
import parseParagraphTags from "../../utils/parseParagraphTags";
import Loading from "../shared/loading/Loading";
import convertDate from "../../utils/convertDate";
const SingleJob = ({ singleJob, menuOpen, job, mapStage }) => {
  function addDashes(string) {
    string = string.split(" ");
    string = string.join("-");
    return string;
  }

  return (
    <>
      {Object.keys(singleJob).length !== 0 && (
        <div className={`${!menuOpen ? "singleJob" : "singleJob--open"}`}>
          <div className="singleJob__inner">
            <div className="singleJob__header">
              <div className="singleJob__title">
                <div className="singleJob__title__name">
                  <div className="singleJob__title__name__title">
                    <h5>
                      {singleJob.title && parse(singleJob.title.rendered)}
                    </h5>
                  </div>
                  <div className="singleJob__title__name__location">
                    <h6>{job.name && job.name}</h6>
                  </div>
                </div>
                <div className="singleJob__title__added">
                  <span>Added 2 days ago</span>
                </div>
              </div>
              <div className="singleJob__desc--alt">
                <div className="singleJob__desc__block">
                  <div className="singleJob__desc__block__label">
                    <span>Sector: </span>
                  </div>
                  <div className="singleJob__desc__block__content">
                    <span>
                      {singleJob?.acf?.vac_sub_sector &&
                        singleJob.acf.vac_sub_sector}
                    </span>
                  </div>
                </div>
                <div className="singleJob__desc__block">
                  <div className="singleJob__desc__block__label">
                    <span>Salary: </span>
                  </div>
                  <div className="singleJob__desc__block__content">
                    <span>
                      {singleJob?.acf?.vac_salary
                        ? singleJob.acf.vac_salary
                        : "n/a"}
                    </span>
                  </div>
                </div>
                <div className="singleJob__desc__block">
                  <div className="singleJob__desc__block__label">
                    <span>Contract: </span>
                  </div>
                  <div className="singleJob__desc__block__content">
                    <span>
                      {singleJob?.contractType && singleJob.contractType}
                    </span>
                  </div>
                </div>
                <div className="singleJob__desc__block">
                  <div className="singleJob__desc__block__label">
                    <span>Start Date: </span>
                  </div>
                  <div className="singleJob__desc__block__content">
                    <span>
                      {singleJob?.acf?.vac_start_date &&
                        convertDate(singleJob.acf.vac_start_date)}
                    </span>
                  </div>
                </div>
              </div>
              <div className="singleJob__ctas">
                <div className="singleJob__ctas__block">
                  <div className="singleJob__ctas__button">
                    <a
                      href={`https://engage-education.com/application-form/?job_position=${addDashes(
                        singleJob.title.rendered
                      )}&job_sector=${
                        singleJob?.acf?.vac_sub_sector &&
                        singleJob.acf.vac_sub_sector
                      }&job_id=${singleJob.id}`}
                    >
                      <button className="btn btn--primary">Apply Now</button>
                    </a>

                    {/* <button
                      className="btn btn--primary"
                      onClick={() => console.log(singleJob)}
                    >
                      Apply Nows
                    </button> */}
                  </div>
                  {/* <div className="singleJob__ctas__button">
                                <a href={singleJob.link}>
                                  <button className="btn btn--alt">
                                    View Vacancy
                                  </button>
                                </a>
                              </div> */}
                </div>

                {/* <div className="singleJob__ctas__block">
                              <div className="singleJob__ctas__button">
                                <button className="btn btn--hollow">
                                  refer a friend
                                </button>
                              </div>
                            </div> */}
                {/* <div className="singleJob__ctas__block">
                              <div className="singleJob__ctas__button">
                                <button
                                  className="btn btn--hollow"
                                  onClick={() => console.log(singleJob)}
                                >
                                  refer a friend
                                </button>
                              </div>
                            </div> */}
              </div>
              <div className="singleJob__jobDesc">
                <div className="wysiwyg">
                  {singleJob?.acf?.vac_job_description &&
                    parse(
                      parseParagraphTags(singleJob.acf.vac_job_description)
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {mapStage === 2 && Object.keys(singleJob).length === 0 && (
        <div className={`${!menuOpen ? "singleJob" : "singleJob--open"}`}>
          <div className="singleJob__inner">
            <div className="singleJob__header">
              <div className="loadingContainer--alt">
                <Loading disabledBackground={true} />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SingleJob;
